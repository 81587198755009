import { IconosSistel } from 'npm-styles-sistel'
import { OrdenResumenStyle } from '../StyledComponents/orderStyle'
import OrderStatus from './OrderStatus'

export default function OrdenSummary({ order }) {
  return (
    <OrdenResumenStyle
      to={`/trabajo/${order._id}`}
      priority={order.priority}
      size={'50px'}
    >
      <div className="flex-between align-items-start">
        <div>
          <h4>{order.cluster}</h4>
          <p className="project">
            <IconosSistel icon="proyectos" width="12" color="#9D98AE" />
            {order.project?.title}
          </p>
        </div>
        <OrderStatus status={order.status} />
      </div>
    </OrdenResumenStyle>
  )
}
