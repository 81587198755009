import EmployeeDelivery from 'components/EmployeeDelivery/EmployeeDelivery'

export default function EmployeeDeliveries({ order, withBox }) {
  if (!order.employeeDeliveries.length) return null
  const displayDeliveries = order.employeeDeliveries.map((delivery) => (
    <EmployeeDelivery {...{ delivery, order, withBox }} key={delivery._id} />
  ))

  return (
    <>
      <h3>Entregas</h3>
      {displayDeliveries}
    </>
  )
}
