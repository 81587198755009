import { IconosSistel, LoaderSistel } from 'npm-styles-sistel'
import OrdenResumen from 'components/Orders/OrderSummary'
import PointSummary from 'components/Points/PointSummary'
import useGlobalData from 'hooks/useGlobalData'
import SinResultados from 'components/Common/SinResultados'
import { FINISHED } from 'utils/constants'

export default function Dashboard() {
  const { data } = useGlobalData(['orderList'])
  const orders = data.orderList

  if (!orders) return <LoaderSistel />

  const displayOrders = orders
    .filter((o) => !o.status || o.status !== FINISHED)
    .map((o) => <OrdenResumen key={o._id} order={o} />)

  return (
    <>
      <PointSummary />
      <h2 className="subtitle">
        <IconosSistel icon="ordenes" />
        Órdenes de trabajo
      </h2>
      {displayOrders.length ? (
        displayOrders
      ) : (
        <SinResultados>Todavía no tienes órdenes asignadas</SinResultados>
      )}
    </>
  )
}
