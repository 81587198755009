import { useContext } from 'react'
import { DataContext } from '../context/DataContext'

export default function (keys = [], keyArgs = []) {
  const { data, getData, loading } = useContext(DataContext)
  const getKeyData = (key, i) => {
    if (key === 'order') {
      return data[key][keyArgs[i]]
    } else {
      return data[key]
    }
  }
  if (keys.length) {
    const { filteredKeys, filteredKeyArgs } = keys.reduce(
      (acc, key, i) => {
        const hasData = Boolean(getKeyData(key, i))
        if (!hasData) {
          acc.filteredKeys.push(key)
          acc.filteredKeyArgs.push(keyArgs[i])
        }
        return acc
      },
      { filteredKeys: [], filteredKeyArgs: [] }
    )
    if (filteredKeys.length) {
      getData(filteredKeys, filteredKeyArgs)
    }
  }
  return { data, loading }
}
