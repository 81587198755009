import { ObjetivoConseguidoStyle } from 'components/StyledComponents/dashboardStyles'

export default function GoalAchieved({ amountEarned, targetHasPassed }) {
  if (!targetHasPassed) return null

  return (
    <ObjetivoConseguidoStyle>
      <div className="party">
        <img src="/images/party.png" alt="Objetivo conseguido" />
      </div>
      <h4>¡Has conseguido tu objetivo!</h4>
      <p>Hasta final de mes podrás seguir consiguiendo más puntos</p>
      <p className="dinero">+{amountEarned}€</p>
    </ObjetivoConseguidoStyle>
  )
}
