import { PieChart, Pie, Cell, Label } from 'recharts'

const PointChart = ({ puntosConseguidos, objetivoMensual }) => {
  const data = [
    { name: 'Puntos conseguidos', value: puntosConseguidos },
    { name: 'Objetivo', value: objetivoMensual - puntosConseguidos }
  ]

  let COLORS = ['#977CFE', '#ece7ff36']
  if (objetivoMensual < puntosConseguidos) {
    COLORS = ['#9AE154', '#E2FFC0']
  }
  const textValue = Math.round((100 * puntosConseguidos) / objetivoMensual)
  return (
    <PieChart width={130} height={130}>
      <Pie
        data={data}
        cx={60}
        cy={60}
        innerRadius={40}
        outerRadius={60}
        fill="#8884d8"
        stroke="transparent"
        paddingAngle={0}
        dataKey="value"
      >
        {data.map((_, index) => (
          <Cell key={index} fill={COLORS[index % COLORS.length]} />
        ))}
        <Label
          value={`${textValue} %`}
          position="center"
          style={{ fill: 'white' }}
        />
      </Pie>
    </PieChart>
  )
}

export default PointChart
