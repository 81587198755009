import { useState } from 'react'
import Select from 'react-select'
import TableSistel from 'components/Common/Table'

const unitMap = {
  Metro: 'm',
  Unidad: 'ud'
}

export default function PointBaremos({ baremos, clients }) {
  const clientOptions = clients.map((c) => ({
    label: c.name,
    value: c._id
  }))

  const [client, setClient] = useState(clientOptions[0])
  const handleSelect = setClient

  const initialValue = clientOptions.reduce(
    (acc, c) => ({ ...acc, [c.value]: [] }),
    {}
  )
  const clientBaremos = baremos
    .filter((b) => b.active)
    .reduce((acc, b) => {
      if (!b.clients || !b.clients.length) return acc
      let result = Object.assign(acc)
      b.clients.forEach((c) => {
        result = {
          ...result,
          [c.client]: [
            ...result[c.client],
            {
              baremo: b._id,
              name: b.description,
              pointValue: `${c.pointValue}/${unitMap[b.unit]}`
            }
          ]
        }
      })
      return result
    }, initialValue)

  const displayData = client
    ? clientBaremos[client.value].map((b, i) => (
        <tr key={b._id + i.toString()}>
          <td>{b.name}</td>
          <td className="text-right">{b.pointValue}</td>
        </tr>
      ))
    : []

  return (
    <div className="mt-3">
      <Select
        options={clientOptions}
        value={client}
        onChange={handleSelect}
        placeholder="Selecciona un cliente"
      />
      <TableSistel
        headers={
          <>
            <th>Descripción</th>
            <th className="text-right">Puntos</th>
          </>
        }
        data={displayData}
      />
    </div>
  )
}
