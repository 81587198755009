import { FormGroupTable } from 'components/StyledComponents/formStyles'
import { Field, FieldArray } from 'formik'

export default function BaremoFormElement({ baremoEntries }) {
  return (
    <>
      <h4>Tareas</h4>
      <FieldArray
        name="baremoEntries"
        render={() => (
          <div>
            {baremoEntries.map((baremo, index) => (
              <FormGroupTable key={index}>
                <label>{baremo.description}</label>
                <Field
                  name={`baremoEntries[${index}].qty`}
                  value={baremo.qty}
                  type="number"
                />
              </FormGroupTable>
            ))}
          </div>
        )}
      />
    </>
  )
}
