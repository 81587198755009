import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { AuthContextProvider } from './context/AuthContext'

import App from './App'

import themeSistel from 'npm-styles-sistel/src/theme'
import { MainStylesSistel } from 'npm-styles-sistel'
import 'npm-styles-sistel/src/bootstrap.css'
import 'react-image-lightbox/style.css';

ReactDOM.render(
  <BrowserRouter>
    <AuthContextProvider>
      <ThemeProvider theme={themeSistel}>
        <MainStylesSistel>
          <App />
        </MainStylesSistel>
      </ThemeProvider>
    </AuthContextProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
