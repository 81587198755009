import TableSistel from 'components/Common/Table'
import { TableStyled } from 'components/StyledComponents/tableStyles'

export default function DeliveryBaremoList({ baremoEntries }) {
  if (!baremoEntries || !baremoEntries.length) return null

  const data = baremoEntries.map(({ baremo, qty }) => (
    <tr key={baremo._id}>
      <td>{baremo.description}</td>
      <td align="right">
        {qty} {baremo.unit === 'Unidad' ? 'u.' : 'm.'}
      </td>
    </tr>
  ))

  return (
    <TableSistel
      headers={
        <>
          <th>Baremo</th>
          <th className="text-right">Cantidad</th>
        </>
      }
      data={data}
    />
  )
}
