import styled from 'styled-components'

export const PendingNotificationsStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.purple};
  margin-bottom: 12px;
  border-radius: 10px;
  padding: 24px;
  display: flex;
  align-items: center;

  p {
    margin-left: 12px;
    color: white;
  }
`

export const NotificacionPEXStyle = styled.div`
  background-color: ${(props) =>
    props.leido ? props.theme.red_light : 'white'};
  border: 1px solid
    ${({ theme, estado }) =>
      estado ? theme.colors.grey_dark : theme.colors.purple};
  margin-bottom: 16px;
  border-radius: 10px;
  padding: 12px;

  .contenido-icono {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .eliminar {
      background: transparent;
    }
  }

  .dot {
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.purple};
    border-radius: 100%;
    color: white;
    margin: 8px 8px 0 0;
  }

  .dia {
    ${({ theme }) => theme.fonts.text_s_bold};
    color: ${({ theme }) => theme.colors.text_light};
    margin-bottom: 0;
  }

  .hora {
    ${({ theme }) => theme.fonts.text_s};
    color: ${({ theme }) => theme.colors.text_light};
    margin-left: 8px;
    margin-bottom: 0;
  }

  h4 {
    text-transform: uppercase;
    color: ${({ theme, estado }) =>
      estado ? theme.colors.text_light : theme.colors.text};
    font-size: 16px;
    font-weight: 800;

    span {
      font-weight: 400;
    }
  }

  p {
    color: ${({ theme, estado }) =>
      estado ? theme.colors.text_light : theme.colors.text};
    font-size: 14px;
    margin-bottom: 6px;
  }

  .leido {
    display: flex;
    justify-content: flex-end;

    .btn-leido {
      ${({ theme }) => theme.fonts.text_s_bold};
      padding: 8px 16px;
      border-radius: 10px;
    }

    .btn-leido-si {
      background-color: ${({ theme }) => theme.colors.grey_mid};
      color: ${({ theme }) => theme.colors.text_light};
      display: flex;
      align-items: center;
    }

    .btn-leido-no {
      background-color: ${({ theme }) => theme.colors.purple};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`
