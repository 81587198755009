import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import FileService from 'service/FileService'
import { postApi, putApi } from 'api/common'

import BrigadeFormElement from './Elements/BrigadeFormElement'
import BaremoFormElement from './Elements/BaremoFormElement'
import AttachmentsFormElement from './Elements/AttachmentsFormElement'
import EmployeeComment from './Elements/EmployeeComment'
import FinalOrderStatus from './Elements/FinalOrderStatus'
import ErrorMessage from '../../StyledComponents/ErrorMessage'

import { ButtonSistel } from 'npm-styles-sistel'

export default function NewEntryForm({ order, delivery }) {
  const history = useHistory()
  const [initialFormState] = useState(initialValues(order, delivery))

  const formik = useFormik({
    initialValues: initialFormState,
    validationSchema: validationSchema(delivery),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true)
        toast.info('Cargando imágenes')

        const uploadedPhotos =
          (await FileService.uploadFiles(photos.map(({ file }) => file))) || []

        const updatedPhotos = delivery
          ? [...delivery.photos, ...uploadedPhotos]
          : uploadedPhotos
        const body = {
          ...values,
          order: {
            ...order,
            status: values.status
          },
          photos: updatedPhotos
        }

        toast.success('Imágenes cargadas correctamente')

        const refresh = () => {
          toast.success('Entrega realizada correctamente.')
          history.push('/')
          location.reload()
        }

        if (delivery) {
          return putApi(
            '/employeedelivery/update',
            `${delivery._id}`,
            body,
            refresh
          )
        }
        return postApi('/employeedelivery', body, refresh)
      } catch (error) {
        console.error(error)
        toast.error('Error al enviar la entrega. Contacte con su superior.')
      }
    }
  })
  const {
    errors,
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    handleChange
  } = formik

  const { baremoEntries, confirmBrigade, photos, status } = values

  const onChange = (imageList) => {
    setFieldValue('photos', imageList)
  }

  if (errors.photos) {
    toast.error(errors.photos)
  }
  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <BaremoFormElement {...{ baremoEntries }} />

        <BrigadeFormElement
          brigade={order.brigade}
          {...{ confirmBrigade, handleChange }}
        />

        <AttachmentsFormElement
          photos={values.photos}
          {...{ photos, onChange }}
          previousImg={delivery?.photos}
        />

        <EmployeeComment {...{ handleChange }} value={values.employeeComment} />
        <FinalOrderStatus {...{ status }} />

        <ErrorMessage message={errors.photos} />
        {status && (
          <ButtonSistel block type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'CARGANDO' : 'ENVIAR'}
          </ButtonSistel>
        )}
      </form>
    </FormikProvider>
  )
}

function initialValues(order, delivery) {
  const baremoEntries = order.baremoList
    ? order.baremoList?.map(({ baremo }) => {
        const baremoInDelivery =
          delivery &&
          delivery?.baremoEntries.find((be) => be.baremo._id === baremo._id)
        return {
          baremo: baremo._id,
          qty: baremoInDelivery ? baremoInDelivery.qty : undefined,
          description: baremo.description,
          pointValue: baremoInDelivery
            ? baremoInDelivery.pointValue
            : baremo.clients?.find(
                (element) => element.client === order.project.client
              ).pointValue
        }
      })
    : []
  return {
    baremoEntries,
    brigadeComment: delivery?.brigadeComment || '',
    confirmBrigade: delivery?.brigadeComment ? 'edited' : '',
    employeeComment: delivery?.employeeComment || '',
    firstEntry: order.employeeDelivery?.lenght || 0,
    photos: [],
    status: '',
    users: order.brigade.users.map(({ _id }) => _id)
  }
}

function validationSchema(delivery) {
  if (!delivery || !delivery?.photos.length) {
    return Yup.array().min(1, 'Necesitas subir al menos una imagen.')
  }
  return Yup.array()
}
