import { Field } from 'formik'
import { IconosSistel } from 'npm-styles-sistel'
import { IN_PROGRESS, PENDING_VALIDATE } from 'utils/constants'

export default function FinalOrderStatus({ status }) {
  return (
    <>
      <h4>¿La orden de trabajo está terminada?</h4>
      <div role="group" className="flex-buttons">
        <label>
          <IconosSistel
            icon="eliminar"
            color={status === PENDING_VALIDATE ? '#FFE9E9' : '#D74C4C'}
            width="32px"
          />

          <Field
            type="radio"
            name="status"
            value={IN_PROGRESS}
            style={{ display: 'none' }}
          />
        </label>
        <label>
          <IconosSistel
            icon="check"
            color={status === IN_PROGRESS ? '#CEFFE2' : '#00D87D'}
            width="32px"
          />
          <Field
            type="radio"
            name="status"
            value={PENDING_VALIDATE}
            style={{ display: 'none' }}
          />
        </label>
      </div>
    </>
  )
}
