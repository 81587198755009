import { BadgeSistel } from 'npm-styles-sistel'
import { CERTIFIED, FINISHED, IN_PROGRESS, PENDING_VALIDATE }from 'utils/constants'

export default function OrderStatus({ status }) {
  return status === IN_PROGRESS ? (
    <BadgeSistel blue>En proceso</BadgeSistel>
  ) : status === PENDING_VALIDATE ? (
    <BadgeSistel warning>Validación pendiente</BadgeSistel>
  ) : status === FINISHED || status === CERTIFIED ? (
    <BadgeSistel success>Finalizada</BadgeSistel>
  ) : (
    <BadgeSistel>Asignada</BadgeSistel>
  )
}
 