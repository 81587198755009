import TableSistel from 'components/Common/Table'
import React from 'react'
import dateService from 'service/dateService'

export default function PointSummary({ data }) {
  const displayData =
    data &&
    data.map((e) => {
      const points = Math.floor(e.points)
      const balance = Math.floor((points - e.monthlyGoal) * e.pointPrice)

      const plus = balance > 0 ? balance : 0

      return (
        <tr key={e._id}>
          <td>{dateService.getLongMonth(e.createdAt)}</td>
          <td className="text-right">{points}</td>
          <td className="text-right">{plus}</td>
        </tr>
      )
    })

  return (
    <TableSistel
      headers={
        <>
          <th>Mes</th>
          <th className="text-right">Puntos</th>
          <th className="text-right">€</th>
        </>
      }
      data={displayData}
    />
  )
}
