import { toast } from 'react-toastify'
import { BASE_PATH, TOKEN } from 'utils/constants'

/**
 *
 * @param {string} path Api path
 * @param {{}} values Body with values of instance
 * @param {Function} cb Callback data function
 * @returns
 */

export async function postApi(path, values, cb) {
  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(TOKEN)
      },
      method: 'POST',
      body: JSON.stringify(values)
    }

    const response = await fetch(`${BASE_PATH}${path}`, options)
    if (!response.ok) throw await response.text()

    if (response.status === 204) {
      return cb && cb()
    }
    const data = await response.json()
    cb && cb(data)
    return true
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function putApi(path, instanceId, values, cb) {
  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(TOKEN)
      },
      method: 'PUT',
      body: JSON.stringify(values)
    }

    const response = await fetch(`${BASE_PATH}${path}/${instanceId}`, options)
    if (!response.ok) throw await response.text()

    return cb()
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function postDocumentApi(values) {
  try {
    const options = {
      headers: {
        Authorization: localStorage.getItem(TOKEN)
      },
      method: 'POST',
      body: values
    }

    const response = await fetch(`${BASE_PATH}/upload`, options)

    if (!response.ok) throw await response.text()

    const data = await response.json()
    return data
  } catch (error) {
    toast.error('Error al subir las imágenes. Contacte con su superior.')
    alert(error)
    console.error(error)
    return null
  }
}

export const changePasswordApi = async (values) => {
  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(TOKEN)
      },
      method: 'PUT',
      body: JSON.stringify(values)
    }

    const response = await fetch(`${BASE_PATH}/user/updatepassword`, options)
    if (!response.ok) throw await response.text()
    toast.success('Contraseña cambiada correctamente')
    return true
  } catch (error) {
    console.error(error)
    return null
  }
}
