export default function BaremoList({ baremoList }) {
  if (!baremoList || !baremoList.length) return null

  const displayBaremos = baremoList.map(({ baremo, estimated }) => (
    <tr key={baremo._id}>
      <td>{baremo.description}</td>
      <td align="right">
        {estimated} {baremo.unit === 'Unidad' ? 'u.' : 'm.'}
      </td>
    </tr>
  ))

  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="table-head">
          <tr>
            <th align="left">Baremo</th>
            <th align="right">Estimación</th>
          </tr>
        </thead>
        <tbody>{displayBaremos}</tbody>
      </table>
    </div>
  )
}
