import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Badge = styled.span`
  display: inline-block;
  padding: 2px 14px;
  font-size: 14px;
  border-radius: 6px;

  color: ${(props) =>
    props.children === 'Asignada'
      ? props.theme.colors.darkBlue
      : props.children === 'Validación pendiente' || props.children === 'P01'
      ? props.theme.colors.warning
      : props.children === 'Finalizada' ||
        props.children === 'Activo' ||
        props.children === 'Finalizado'
      ? props.theme.colors.success
      : props.children === 'Incidencia'
      ? props.theme.colors.danger
      : props.children === 'En proceso'
      ? props.theme.colors.blue
      : props.children === 'Certificadas'
      ? '#00c100'
      : '#A3A7AA'};

  background-color: ${(props) =>
    props.children === 'Asignada'
      ? props.theme.colors.primary_light
      : props.children === 'Validación pendiente' || props.children === 'P01'
      ? props.theme.colors.orange_light
      : props.children === 'Finalizada' ||
        props.children === 'Activo' ||
        props.children === 'Finalizado'
      ? props.theme.colors.green_light
      : props.children === 'Incidencia'
      ? props.theme.colors.red_light
      : props.children === 'En proceso'
      ? props.theme.colors.blue_light
      : props.children === 'Certificadas'
      ? 'rgba(0, 255,0, 0.2)'
      : '#F1F1F1'};
`

export const Button = styled(Link)`
  display: inline-block;
  padding: 8px 20px;
  font-size: 16px;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 1px;
  border: none;
  outline: none;
  cursor: pointer;

  color: ${(props) =>
    props.success
      ? 'white'
      : props.danger
      ? 'white'
      : props.theme.colors.darkText};

  background-color: ${(props) =>
    props.success
      ? props.theme.colors.success
      : props.danger
      ? props.theme.colors.danger
      : props.theme.colors.grey};
`

export const ActionButton = styled.button`
  display: inline-block;
  padding: 8px 20px;
  font-size: 16px;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 1px;
  border: none;
  outline: none;
  cursor: pointer;

  color: ${(props) =>
    props.success
      ? 'white'
      : props.danger
      ? 'white'
      : props.theme.colors.darkText};

  background-color: ${(props) =>
    props.success
      ? props.theme.colors.success
      : props.danger
      ? props.theme.colors.danger
      : props.theme.colors.grey};
`

export const ImageButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`

export const LinkTabla = styled(Link)`
  color: ${(props) => props.theme.colors.darkText};
  font-weight: 600;
  font-size: 16px;
  padding: 0;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid;
  text-decoration: none;
  transition: color 0.3s ease;
  outline: none;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
`

export const ActionLink = styled.button`
  color: ${(props) => props.theme.colors.darkText};
  font-weight: 600;
  background-color: transparent;
  border: none;
  font-size: 16px;
  border-bottom: 2px solid;
  outline: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
`

export const HeaderWithButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 42px;

  .buttons {
    display: flex;
    gap: 12px;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-right: 1em;
    button {
      font-weight: 500;
      font-size: 1.1em;
    }
  }

  #bonificacion {
    color: green;
  }

  #penalizacion {
    color: red;
  }
`

export const Prioridad = styled.span`
  padding: 2px 9px;
  border-radius: 6px;
  font-size: 14px;

  color: ${(props) =>
    props.children.includes('P1')
      ? props.theme.colors.danger
      : props.children === 'P2'
      ? props.theme.colors.warning
      : '#A3A7AA'};

  background-color: ${(props) =>
    props.children.includes('P1')
      ? props.theme.colors.red_light
      : props.children === 'P2'
      ? props.theme.colors.orange_light
      : '#A3A7AA'};
`

export const GoBack = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 32px;

  button {
    border: none;
    background-color: ${(props) => props.theme.colors.primary_light};
    width: 30px;
    height: 30px;
    border-radius: 6px;
    color: ${(props) => props.theme.colors.darkBlue};
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  img {
    width: 70px;
    height: 70px;
    border-radius: 6px;
    object-fit: cover;
  }

  h3 {
    color: ${(props) => props.theme.colors.darkText};
    font-weight: 400;
    padding-top: 4px;
    font-size: 18px;
  }

  .nombre-estado {
    display: flex;
    align-items: center;
    gap: 18px;
  }
`

export const GoBackContent = styled.div`
  padding-left: 46px;
`

export const CenteredContent = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: ${(props) => props.theme.colors.darkBlue};
    font-size: 26px;
    padding-bottom: 42px;
  }
`

export const GroupData = styled.div`
  padding-bottom: 20px;

  span {
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.text};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 2em;
  }

  p {
    font-size: 16px;
  }

  .empty {
    color: ${(props) => props.theme.colors.text};
    font-style: italic;
    font-size: 14px;
  }

  ul {
    padding: 0;
    margin-top: 12px;

    li {
      list-style: none;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
      color: ${(props) => props.theme.colors.darkText};

      &:last-child {
        border-bottom: none;
      }
    }
  }
`

export const MensajeError = styled.div`
  background-color: ${(props) => props.theme.colors.orange};
  border-radius: 6px;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;

  p,
  i {
    color: white;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
`

export const BloqueInformacionAzul = styled.div`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  padding: 24px;
  border-radius: 6px;

  .flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    h5 {
      color: ${({ theme }) => theme.colors.blue};
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 1px;
    }
  }

  p {
    color: white;
    font-size: 48px;
    font-weight: 300;
    text-transform: uppercase;

    span {
      font-size: 20px;
    }
  }
`

export const SinResultadosStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  p {
    color: ${({ theme }) => theme.colors.text_light};
    margin-top: 24px;
    font-size: 14px;
  }

  img {
    width: 50px;
    margin: 42px 0 24px 0;
  }
`


