import { LoaderSistel } from 'npm-styles-sistel'
import GoalAchieved from 'components/Points/GoalAchieved'
import HistoryByMonths from 'components/Points/HistoryByMonth'
import PointSummary from 'components/Points/PointSummary'
import useGlobalData from 'hooks/useGlobalData'
import { Tabs, Tab } from 'react-bootstrap'
import PointBaremos from 'components/Points/PointBaremos'
import PointOrders from 'components/Points/PointOrders'
import useAuth from 'hooks/useAuth'

export default function Puntos() {
  const {
    auth: { user }
  } = useAuth()
  const { points, monthlyGoal, department, pointPrice, config, pointHistory } =
    user
  const { data } = useGlobalData(['baremos', 'clients'])
  const { baremos, clients } = data
  if (!baremos || !clients) return <LoaderSistel />

  const pointsSummary = Math.round(points.reduce((a, b) => a + b.qty, 0))
  const employeeGoal = monthlyGoal || department.monthlyGoal
  const determinePointPrice = pointPrice || config.pointPrice

  const targetHasPassed = employeeGoal < pointsSummary
  const amountEarned = (pointsSummary - employeeGoal) * determinePointPrice

  return (
    <>
      <PointSummary />
      <GoalAchieved {...{ targetHasPassed, amountEarned }} />
      <Tabs defaultActiveKey="puntos" className="mt-4">
        <Tab eventKey="puntos" title="Puntos">
          <PointOrders {...{ points }} />
        </Tab>
        <Tab eventKey="baremos" title="Baremos">
          <PointBaremos {...{ baremos, clients }} />
        </Tab>
        <Tab eventKey="historial" title="Historial">
          <HistoryByMonths {...{ pointHistory }} />
        </Tab>
      </Tabs>
    </>
  )
}
