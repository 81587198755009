import { LightboxImageSistel } from 'npm-styles-sistel'

export default function DeliveryAttachments({ photos }) {
  return (
    <>
      <h4>Adjuntos</h4>
      <LightboxImageSistel images={photos} />
    </>
  )
}
