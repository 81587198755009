import { ButtonSistel } from 'npm-styles-sistel'
import { useHistory } from 'react-router-dom'

export default function NewDeliveryButton({ order, ...rest }) {
  const history = useHistory()
  const navigate = () =>
    history.push({
      pathname: `/registrar-entrega/${order._id}`,
      state: { delivery: rest.delivery }
    })

  return (
    <ButtonSistel block onClick={navigate} {...rest}>
      {rest.btnText || 'Nueva entrega'}
    </ButtonSistel>
  )
}
