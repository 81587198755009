import { createContext, useState, useMemo, useCallback } from 'react'

import { fetchData } from 'service/api'
import useAuth from '../hooks/useAuth'

export const DataContext = createContext({})

export default function DataContextProvider({ children }) {
  const { auth } = useAuth()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(initialState())

  const getUrl = (key, param) => {
    const urls = {
      orderList: '/order/pex/orderList',
      baremos: '/baremo',
      clients: '/client',
      order: `/order/pex/${param}`
    }
    return urls[key]
  }

  const handleResponse = (key, newData, response) => {
    if (key === 'order') {
      newData.order = {}
      newData.order[response._id] = response
    } else {
      newData[key] = response
    }
  }

  const getData = useCallback(async (keys, keyArgs) => {
    try {
      const promises = []
      const responseKeys = []
      for (const [i, key] of Object.entries(keys)) {
        setLoading(true)
        promises.push(fetchData(auth.token, getUrl(key, keyArgs[i])))
        responseKeys.push(key)
      }
      const responses = await Promise.all(promises)
      const newData = {}
      responses.forEach((response, i) => {
        handleResponse(responseKeys[i], newData, response)
      })
      setData((prev) => {
        const newOrder = { ...prev.order, ...(newData.order || {}) }
        return { ...prev, ...newData, order: newOrder }
      })
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }, [])

  /**
   *
   * @param {string} identifier Name of data type. Ex: 'notifications'
   * @param {[{}]} newData Object array with updated data
   * @returns
   */
  const updateData = (identifier, newData) =>
    setData({ ...data, [identifier]: newData })

  const globalData = useMemo(
    () => ({
      loading,
      data,
      getData,
      updateData
    }),
    [loading, data]
  )

  return (
    <DataContext.Provider value={globalData}>{children}</DataContext.Provider>
  )
}

function initialState() {
  return {
    orderList: null,
    baremos: null,
    clients: null,
    order: {}
  }
}
