import React, { useState } from 'react'

import { loginApi } from 'api/auth'
import useAuth from 'hooks/useAuth'
import { ButtonSistel, InputSistel } from 'npm-styles-sistel'
import { LoginStyled } from './loginStyle'

export default function Login() {
  return (
    <LoginStyled>
       <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 col-xs-12 all-content">
            <div className="content">
              <img src="/images/logo-login.png" alt="Sistel Empleados" />
              <h1>Let's work!</h1>
              <LoginForm />
            </div>
            <p className="copyright">Sistel®. Todos los derechos reservados</p>
          </div>
        </div>
      </div>
      
    </LoginStyled>
  )
}

function LoginForm() {
  const [form, setForm] = useState({ dni: '', password: '' })
  const { login } = useAuth()

  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value })

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      loginApi(form, login)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <InputSistel
        type="text"
        placeholder="DNI"
        col="col-md-12"
        name="dni"
        value={form.dni}
        onChange={handleChange}
      />
      <InputSistel
        type="password"
        placeholder="Contraseña"
        col="col-md-12"
        name="password"
        value={form.password}
        onChange={handleChange}
      />
      <ButtonSistel secondary>Iniciar sesión</ButtonSistel>
      </div>
      
    </form>
  )
}
