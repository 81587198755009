import styled from 'styled-components'
import { Link } from 'react-router-dom'
import themeSistel from 'npm-styles-sistel/src/theme'

export const OrdenResumenStyle = styled(Link)`
  color: ${themeSistel.colors.primary};
  display: block;
  padding: 16px;
  border-radius: 10px;
  margin-top: 12px;
  box-shadow: ${themeSistel.shadows.main_shadow};

  &:hover {
    box-shadow: ${themeSistel.shadows.light_shadow};
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 15px;
    width: 6px;
    height: ${({ size }) => size};
    border-radius: 0 20px 20px 0;
    background-color: ${({ priority }) =>
      priority === 'P01' ? themeSistel.colors.danger : 'transparent'};
  }

  .dropdown-toggle {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: ${themeSistel.colors.primary_light};

    svg {
      margin-right: 0;
    }
  }

  .dropdown-menu {
    box-shadow: ${themeSistel.shadows.main_shadow};
    border: none;
    margin-top: 8px !important;
    min-width: 200px;
    padding: 24px;
    border-radius: 10px;
  }

  h4 {
    font-size: 19px !important;
    font-weight: 800;
    display: flex;
    align-items: center;
    color: ${themeSistel.colors.text};

    img {
      width: 18px;
      margin-right: 8px;
    }
  }

  .brigade {
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 700;
    color: ${themeSistel.colors.primary};
  }

  .project {
    display: flex;
    align-items: center;
    ${themeSistel.fonts.text_s};
    color: ${themeSistel.colors.text_light};
  }

  svg {
    margin-right: 6px;
  }

  .progress-order {
    display: flex;
    align-items: center;

    progress {
      width: 82%;
    }
  }

  .uuii {
    h5 {
      font-size: 14px;
    }

    p {
      text-transform: uppercase;
      font-size: 10px;
    }
  }
`

export const OrdenDetallesPEXstyled = styled.div`

  h2 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text};
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  h3 {
    ${({theme}) => theme.fonts.h3};
    color: ${({ theme }) => theme.colors.text};
    margin: 24px 0;
  }

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 42px;

    .mapa {
      background-color: ${({ theme }) => theme.colors.primary_light};
      padding: 16px;
      border-radius: 6px;
    }
  }

  .flex-data {
    display: flex;
    align-items: flex-start;

    & > div {
      width: 50%;
    }
  }

  .button-xl {
    display: flex;
    margin-bottom: 24px;
    a {
      width: 100%;
      text-align: center;
      padding: 20px;
      text-transform: uppercase;
    }
  }
`
export const ButtonGoBack = styled.button`
width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.grey_mid};
    margin-right: 12px;
`