export default function TableSistel({ headers, data }) {
  return (
    <div className="table-responsive mt-4">
      <table className="table">
        <thead className="table-head">
          <tr>{headers}</tr>
        </thead>
        <tbody>{data}</tbody>
      </table>
    </div>
  )
}
