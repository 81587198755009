import useAuth from 'hooks/useAuth'
import {
  AvatarImageSistel,
  BadgeSistel,
  ButtonSistel,
  DataGroupSistel,
  IconosSistel
} from 'npm-styles-sistel'
import { useHistory } from 'react-router'
import styled from 'styled-components'

// import ChangePassword from 'components/Common/Forms/ChangePassword'
// <ChangePassword />

const ProfileStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  text-align: center;

  h1 {
    ${({ theme }) => theme.fonts.h2};
    margin: 8px 0 12px 0;
  }

  hr {
    width: 100%;
  }

  span {
    margin-bottom: 12px;
  }

  .icons {
    display: flex;
    svg {
      margin: 6px;
    }
  }
`

export default function Usuario() {
  const history = useHistory()
  const {
    auth: { user },
    logout
  } = useAuth()

  const role =
    user.department.name === 'Fusionadores' ? (
      <BadgeSistel blue>Fusionador</BadgeSistel>
    ) : (
      <BadgeSistel purple>Celador</BadgeSistel>
    )

  const goChangePassword = () => {
    history.push('/perfil/cambiar-password')
  }

  return (
    <ProfileStyled>
      <AvatarImageSistel image={user.avatar} width="80px" />
      <h1>
        {user.name} {user.lastname}
      </h1>
      {role}
      <div className="icons">
        {user.canBeManager && <IconosSistel icon="leader" />}
        {user.driverLicense && <IconosSistel icon="vehiculos" width="20" />}
      </div>

      <hr />
      <DataGroupSistel title="DNI" data={user.dni} />
      <DataGroupSistel
        title="Brigada"
        data={user.brigade?.name || 'Sin asignar'}
      />
      <ButtonSistel edit block className="mb-2" onClick={goChangePassword}>
        Cambiar contraseña
      </ButtonSistel>
      <ButtonSistel danger block onClick={logout}>
        Cerrar sesión
      </ButtonSistel>
    </ProfileStyled>
  )
}
