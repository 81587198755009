import Attached from 'components/LightBoxImage/Attached'
import OrderStatus from 'components/Orders/OrderStatus'
import { ButtonGoBack } from 'components/StyledComponents/orderStyle'
import { IconosSistel } from 'npm-styles-sistel'
import { useHistory } from 'react-router'

export default function WorkHeader({ order, box }) {
  const history = useHistory()

  return (
    <>
      <div className="flex-between mb-5">
        <div className="d-flex align-items-center">
          <ButtonGoBack onClick={() => history.goBack()}>
            <IconosSistel icon="goBack" width="8px" />
          </ButtonGoBack>

          <h1 className="mr-3">{box || order.cluster}</h1>
          <OrderStatus status={order.status} />
        </div>
        <Attached images={order.attachments} />
      </div>
    </>
  )
}
