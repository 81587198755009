import styled from 'styled-components'

export const MainContainer = styled.div`
  padding-top: 62px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }


  .subtitle {
    color: ${({ theme }) => theme.colors.text};
    margin-top: 46px !important;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    ${({ theme }) => theme.fonts.h3};

    svg {
      margin-right: 8px;
    }
  }

  .flex-columns {
    display: flex;
    align-items: flex-start;
    gap: 34px;

    .large-column {
      width: 100%;
    }
  }

  .filters-box {
    border: 1px solid ${(props) => props.theme.colors.grey};
    border-radius: 6px;
    padding: 18px;
    margin-bottom: 16px;

    h2 {
      padding-bottom: 16px;
    }
  }

  @media (max-width: 1000px) {
    padding: 90px 15px;
  }
`
