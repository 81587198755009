import Resizer from 'react-image-file-resizer'

import { postDocumentApi } from 'api/common'

class FileService {
  /**
   *
   * @param {Array} files Array of files directly from input
   * @returns
   */

  async resizeFile(file) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1024,
        1024,
        'JPEG',
        100,
        0,
        (response) => {
          resolve(response)
        },
        'file'
      )
    })
  }

  async uploadFiles(files) {
    if (!files || !files.length) return

    const formData = new FormData()

    try {
      if (files.length === 1) {
        const resizedFile = await this.resizeFile(files[0])
        formData.append('file', resizedFile, resizedFile.name)
        return await postDocumentApi(formData)
      }

      for (const file of files) {
        const resizedFile = await this.resizeFile(file)
        formData.append('file', resizedFile, resizedFile.name)
      }
      return await postDocumentApi(formData)
    } catch (error) {
      return undefined
    }
  }
}

export default new FileService()
