export default function BoxList({ boxList }) {
  if (!boxList.length) return null

  const displayBoxes = boxList.map((box) => (
    <tr key={box._id}>
      <td align="left">{box.name}</td>
      <td align="right">{box.uuii}</td>
    </tr>
  ))

  return (
    <div className="table-responsive mt-4">
      <table className="table">
        <thead className="table-head">
          <tr>
            <th align="left">Caja</th>
            <th className="text-right">UUII</th>
          </tr>
        </thead>
        <tbody>{displayBoxes}</tbody>
      </table>
    </div>
  )
}
