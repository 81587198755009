import { Field } from 'formik'
import { BadgeSistel, IconosSistel, InputSistel } from 'npm-styles-sistel'

export default function BrigadeFormElement({
  brigade,
  confirmBrigade,
  handleChange,
  value
}) {
  return (
    <div className="brigada">
      <h4>¿Son correctos tus compañeros de brigada de hoy?</h4>
      <div className="users">
        {brigade.users.map((user) => (
          <p key={user._id} className="brigade-user">
            {user.name} {user.lastname}
          </p>
        ))}
        {confirmBrigade === 'confirmed' && (
          <BadgeSistel success className="mt-2">
            Brigada confirmada
          </BadgeSistel>
        )}
      </div>

      <div role="group" className="flex-buttons">
        <label>
          <IconosSistel
            icon="eliminar"
            color={confirmBrigade === 'confirmed' ? '#FFE9E9' : '#D74C4C'}
            width="32px"
          />
          <Field
            type="radio"
            name="confirmBrigade"
            value="edited"
            style={{ display: 'none' }}
          />
        </label>
        <label>
          <IconosSistel
            icon="check"
            color={confirmBrigade === 'edited' ? '#CEFFE2' : '#00D87D'}
            width="32px"
          />
          <Field
            type="radio"
            name="confirmBrigade"
            value="confirmed"
            style={{ display: 'none' }}
          />
        </label>
      </div>
      {confirmBrigade === 'edited' && (
        <InputSistel
          name="brigadeComment"
          as="textarea"
          placeholder="¿Ha faltado alguien o ha venido alguien más?"
          onChange={handleChange}
          {...{ value }}
        />
      )}
      <hr />
    </div>
  )
}
