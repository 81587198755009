import { useRef, useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'

import BrigadeInfo from './BrigadeInfo'
import PreOpenButton from './PreOpenButton'
import DeliveryBaremoList from './DeliveryBaremoList'
import DeliveryAttachments from './DeliveryAttachments'
import DeliveryBoxAttachments from './DeliveryBoxAttachments'
import { NewDeliveryButton } from 'components/Order'
import { EmployeeDeliveryStyle } from 'components/StyledComponents/employeeDeliveryStyle'

import { CANCELLED, VALIDATED } from 'utils/constants'

import { BadgeSistel, DataGroupSistel } from 'npm-styles-sistel'

export default function EmployeeDelivery({ delivery, order, withBox }) {
  const [open, setOpen] = useState(false)
  const { status } = delivery
  const ref = useRef()

  const deliveryDate = new Date(delivery.createdAt)
  const date = deliveryDate.toLocaleDateString('es-ES')
  const time = deliveryDate.toLocaleTimeString('es-ES', {
    hour: '2-digit',
    minute: '2-digit'
  })

  const deliveryStatusHashmap = {
    VALIDATED: 'validada',
    CANCELLED: 'cancelada',
    PENDING: 'pendiente'
  }

  const deliveryStatus =
    status === VALIDATED ? (
      <BadgeSistel success>Validada</BadgeSistel>
    ) : status === CANCELLED ? (
      <BadgeSistel danger>Cancelada</BadgeSistel>
    ) : (
      <BadgeSistel warning>Validación pendiente</BadgeSistel>
    )

  const determineAttachmentComponent = withBox ? (
    <DeliveryBoxAttachments photos={delivery.boxPhotos} />
  ) : (
    <DeliveryAttachments photos={delivery.photos} />
  )

  const deliveryButton = delivery.status === CANCELLED && (
    <NewDeliveryButton
      btnText="Editar entrega cancelada"
      {...{ order, delivery }}
      danger
    />
  )

  return (
    <EmployeeDeliveryStyle>
      <PreOpenButton
        status={deliveryStatus}
        {...{ date, time, open, setOpen }}
      />
      <Collapse in={open} ref={ref}>
        <div id="delivery" className="mt-4">
          <div className={deliveryStatusHashmap[status]}>
            <BrigadeInfo
              users={delivery.users}
              brigadeComment={delivery.brigadeComment}
            />
            {delivery.employeeComment && (
              <DataGroupSistel
                title="Comentario del empleado"
                data={delivery.employeeComment}
              />
            )}
            {delivery.supervisorComment && (
              <DataGroupSistel
                title="Comentario del supervisor"
                data={delivery.supervisorComment}
              />
            )}
            <DeliveryBaremoList baremoEntries={delivery.baremoEntries} />
            {determineAttachmentComponent}
            {deliveryButton}
          </div>
        </div>
      </Collapse>
    </EmployeeDeliveryStyle>
  )
}
