import { CELADOR } from 'utils/constants'

import EntryOrder from 'components/NewEntry/EntryOrder'

import { TrabajoPEXstyle } from 'components/NewEntry/NewEntryStyle'
import { ButtonSistel } from 'npm-styles-sistel'

export default function CeladorSelector({ deliveries, nextStep }) {
  const displayDeliveries = deliveries.map((delivery) => (
    <EntryOrder
      key={delivery._id}
      onClick={() => nextStep(CELADOR, delivery)}
      {...{ delivery }}
    />
  ))

  return (
    <TrabajoPEXstyle>
      <h1 className="mb-3">Editar entregas canceladas</h1>
      {displayDeliveries}
      <h1 className="mb-2">Crear nueva entrega</h1>
      <ButtonSistel block onClick={() => nextStep()}>
        Crear nueva entrega
      </ButtonSistel>
    </TrabajoPEXstyle>
  )
}
