export default function PreOpenButton({ open, setOpen, date, status, time }) {

  return (
    <button className="resumen" onClick={() => setOpen(!open)}>
      <div>
        <h2>{date}</h2>
        {status}
      </div>
    </button>
  )
}
