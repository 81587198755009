import styled from 'styled-components'
import themeSistel from 'npm-styles-sistel/src/theme'

export const UserNavbarStyled = styled.div`
  background-color: ${themeSistel.colors.white};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  filter: drop-shadow(0px -5px 15px rgba(192, 185, 198, 0.3));
  
  ul {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;

    a {
      text-decoration: none;
    }
    li {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .counter {
        position: relative;
        top: 16px;
        right: -14px;
        font-size: 10px;
        background-color: ${themeSistel.colors.warning};
        color: white;
        padding: 2px 6px;
        font-weight: 500;
        border-radius: 7px;
        margin-top: -20px;
      }
      svg {
        padding-bottom: 6px;
      }
      p {
        text-transform: uppercase;
        font-size: 10px;
        color: ${themeSistel.colors.dark_purple_mid};
        font-weight: 500;
      }
    }
    .active-navitem {
      p {
        color: ${themeSistel.colors.purple_mid};
      }
    }
    .registrar-entrega {
      width: 50px;
      height: 50px;
      border-radius: 14px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
