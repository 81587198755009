import useGlobalData from 'hooks/useGlobalData'
import { TrabajoPEXstyle } from 'components/NewEntry/NewEntryStyle'
import { LoaderSistel } from 'npm-styles-sistel'
import { FINISHED, PENDING_VALIDATE } from 'utils/constants'
import SinResultados from 'components/Common/SinResultados'
import EntryOrder from 'components/NewEntry/EntryOrder'
import { useHistory } from 'react-router'

export default function RegistrarEntrega() {
  const history = useHistory()
  const { data } = useGlobalData(['orderList'])
  const orders = data.orderList

  if (!orders) return <LoaderSistel />

  const openOrders = orders
    .filter(
      (order) => order.status !== FINISHED && order.status !== PENDING_VALIDATE
    )
    .map((orden) => (
      <EntryOrder
        onClick={() => history.push(`/registrar-entrega/${orden._id}`)}
        key={orden._id}
        order={orden}
      />
    ))

  return (
    <TrabajoPEXstyle>
      <div className="ordenes">
        {openOrders.length ? (
          openOrders
        ) : (
          <SinResultados>Todavía no tienes órdenes asignadas</SinResultados>
        )}
      </div>
    </TrabajoPEXstyle>
  )
}
