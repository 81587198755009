import { capitalize } from './stringService'

class DateService {
  constructor() {
    this.currentDate = new Date()
    this.currentDay = this.currentDate.getDate()
    this.currentMonth = this.currentDate.getMonth() + 1
    this.lastMonth = this.currentMonth <= 1 ? 12 : this.currentMonth - 1
    this.currentYear = this.currentDate.getFullYear()
    this.lastYear = this.currentYear - 1
  }

  getCurrentDateString() {
    return `${this.currentYear} ${this.currentMonth} ${this.currentDay}`
  }

  getLastMonthDateString() {
    return `${this.currentYear} ${this.lastMonth} ${this.currentDay}`
  }

  getCustomDateString(YYYY) {
    return (MM) => (DD) =>
      `${YYYY || this.currentYear} ${MM || this.currentMonth} ${
        DD || this.currentDay
      }`
  }

  compareMonthAndYear(date) {
    const fecha = new Date(date)
    const month = fecha.getMonth() + 1
    const year = fecha.getFullYear()
    return month === this.currentMonth && year === this.currentYear
  }

  getLongMonth(date) {
    return capitalize(
      new Date(date).toLocaleDateString('es-ES', { month: 'long' })
    )
  }

  M_YYYY(date) {
    const requiredDate = new Date(date)
    return `${this.getLongMonth(date)} ${requiredDate.getFullYear()}`
  }
}

export default new DateService()
