import ImageUploading from 'react-images-uploading'
import ErrorMessage from 'components/StyledComponents/ErrorMessage'
import { postApi } from 'api/common'
import { ButtonSistel, LightboxImageSistel } from 'npm-styles-sistel'

export default function ImageInput({
  onChangeImages,
  value,
  selector,
  errors,
  maxNumber = 10,
  previousImg = false
}) {
  const titleHashMap = {
    panoramic: 'panoramicas',
    closedBox: 'de torpedo/CTO cerrado',
    openBox: 'de torpedo/CTO abierto',
    cableLabeling: 'cableado',
    powerMeasure: 'medición de potencia',
    bells: 'timbres',
    portal: 'portal',
    ctoTrays: 'bandejas de torpedo/CTO'
  }

  const errorMessage = errors && errors[selector]

  return (
    <div>
      <ErrorMessage message={errorMessage} />
      <h4 className="mb-4">Fotos {titleHashMap[selector]}</h4>
      {previousImg && <LightboxImageSistel images={previousImg} />}
      <ImageUploading
        multiple
        value={value}
        onChange={onChangeImages(selector)}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({ imageList, onImageUpload, onImageRemove, dragProps }) => {
          const handleDeleteImage = (index) => {
            // TODO: Delete postApi call, temporary checking
            const urlToDelete = value[index]
            postApi('/user/imageToDelete', { urlToDelete })
            onImageRemove(index)
          }
          return (
            <div className="upload__image-wrapper mb-4">
              <div className="lista-imagenes">
                {imageList.map((image, index) => (
                  <button
                    type="button"
                    key={image}
                    onClick={() => handleDeleteImage(index)}
                  >
                    <img src={image} />
                  </button>
                ))}
              </div>
              <ButtonSistel
                type="button"
                block
                edit
                onClick={onImageUpload}
                {...dragProps}
              >
                + Subir fotos
              </ButtonSistel>
            </div>
          )
        }}
      </ImageUploading>
      <hr />
    </div>
  )
}
