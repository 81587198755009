import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'

import { postApi, putApi } from 'api/common'
import FileService from 'service/FileService'
import { IN_PROGRESS } from 'utils/constants'

import ImageInput from './Elements/BoxImageInput'
import EmployeeComment from './Elements/EmployeeComment'
import BrigadeFormElement from './Elements/BrigadeFormElement'

import {
  FormGroupTable,
  HeaderEntry
} from 'components/StyledComponents/formStyles'
import ErrorMessage from 'components/StyledComponents/ErrorMessage'
import { ButtonSistel } from 'npm-styles-sistel'

export default function NewEntryWithBox({ order, box }) {
  const history = useHistory()
  const hasDelivery = Boolean(box.delivery)
  const [initialFormState] = useState(initialValues(order, box, hasDelivery))
  const [isUploading, setIsUploading] = useState(false)

  const formik = useFormik({
    initialValues: initialFormState,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validationSchema(box.delivery),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true)

        const { photos, ...restBody } = values

        const refresh = () => {
          toast.success('Entrega realizada correctamente.')
          history.push('/')
          location.reload()
        }

        const updatedBoxPhotos = (delivered, newPhotos) => {
          const boxPhotos = {}
          for (const key in photos) {
            boxPhotos[key] = delivered[key]
              ? newPhotos[key]
                ? [...delivered[key], ...newPhotos[key]]
                : [...delivered[key]]
              : newPhotos[key] || null
          }
          return boxPhotos
        }

        const body = {
          ...restBody,
          order: {
            ...order,
            status: values.status
          },
          boxPhotos: hasDelivery
            ? updatedBoxPhotos(box.delivery.boxPhotos, photos)
            : photos
        }

        if (hasDelivery) {
          return putApi(
            '/employeedelivery/update',
            `${box.delivery._id}`,
            body,
            refresh
          )
        }
        postApi('/employeedelivery', body, refresh)
      } catch (error) {
        console.error(error)
      }
    }
  })

  const {
    errors,
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    handleChange
  } = formik

  const onChangeImages = (field) => async (imageList) => {
    const filesToUpload = imageList
      .filter((img) => img?.data_url)
      .map(({ file }) => file)
    if (filesToUpload.length) {
      toast.info('Subiendo imágenes...')
      setIsUploading(true)
      const photosLinks = await FileService.uploadFiles(filesToUpload)
      setFieldValue(`photos.${field}`, [
        ...values.photos[field],
        ...photosLinks
      ])
      setIsUploading(false)
    } else {
      setFieldValue(`photos.${field}`, imageList)
    }
  }

  const displayImageInputs = Object.keys(values.photos).map((selector) => {
    return (
      <ImageInput
        key={selector}
        value={values.photos[selector]}
        errors={errors.photos}
        previousImg={hasDelivery && box.delivery.boxPhotos[selector]}
        {...{ onChangeImages, selector }}
      />
    )
  })

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <HeaderEntry>
          <h3>Tareas</h3>
          <h3>Cantidad</h3>
        </HeaderEntry>

        <FieldArray
          name="baremoEntries"
          render={() => (
            <div>
              {values.baremoEntries?.map((baremo, index) => {
                return (
                  <FormGroupTable key={baremo.baremo + index}>
                    <label>{baremo.description}</label>
                    <Field
                      name={`baremoEntries[${index}].qty`}
                      value={baremo.qty}
                      type="number"
                    />
                  </FormGroupTable>
                )
              })}
            </div>
          )}
        />

        <BrigadeFormElement
          brigade={order.brigade}
          value={values.brigadeComment}
          confirmBrigade={values.confirmBrigade}
          {...{ handleChange }}
        />

        {displayImageInputs}

        <EmployeeComment {...{ handleChange }} value={values.employeeComment} />

        {errors.photos && <ErrorMessage message="Revisa las imágenes" />}

        <ButtonSistel
          block
          type="submit"
          disabled={isUploading || isSubmitting}
        >
          {isUploading
            ? 'CARGANDO IMÁGENES...'
            : isSubmitting
            ? 'ENVIANDO ENTREGA...'
            : 'ENVIAR'}
        </ButtonSistel>
      </form>
    </FormikProvider>
  )
}

function initialValues(order, box, hasDelivery) {
  const baremoEntries = order.baremoList
    ? order.baremoList?.map(({ baremo }) => {
        const baremoInDelivery =
          hasDelivery &&
          box.delivery?.baremoEntries.find((be) => be.baremo._id === baremo._id)
        return {
          baremo: baremo._id,
          qty: baremoInDelivery ? baremoInDelivery.qty : undefined,
          description: baremo.description,
          pointValue: baremoInDelivery
            ? baremoInDelivery.pointValue
            : baremo.clients?.find(
                (element) => element.client === order.project.client
              ).pointValue
        }
      })
    : []

  const photos = {
    panoramic: [],
    closedBox: [],
    openBox: [],
    ctoTrays: [],
    cableLabeling: [],
    powerMeasure: [],
    portal: [],
    bells: []
  }

  const hasBrigadeComment = hasDelivery && box.delivery.brigadeComment

  return {
    baremoEntries,
    boxId: box._id,
    status: IN_PROGRESS,
    brigadeComment: hasBrigadeComment || '',
    confirmBrigade: hasBrigadeComment ? 'edited' : '',
    employeeComment: hasDelivery ? box.delivery.employeeComment : '',
    firstEntry: order.employeeDelivery?.lenght || 0,
    users: order.brigade.users?.map(({ _id }) => _id) || [],
    photos
  }
}

function validationSchema(delivery) {
  const arrayErrorGenerator = (photoType, photoProp) => {
    if (!delivery || !delivery.boxPhotos[photoProp]) {
      return Yup.array().min(1, `Debes subir al menos una imagen ${photoType}.`)
    }
    return Yup.array()
  }

  const photosSubSchema = {
    panoramic: arrayErrorGenerator('panorámica', 'panoramic'),
    closedBox: arrayErrorGenerator('de la caja cerrada', 'closedBox'),
    openBox: arrayErrorGenerator('de la caja abierta', 'openBox'),
    cableLabeling: arrayErrorGenerator('del cableado', 'cableLabeling')
    // powerMeasure: arrayErrorGenerator('de la medición de potencia', 'powerMeasure')
  }

  const validation = {
    photos: Yup.object(photosSubSchema)
  }

  return Yup.object(validation)
}
