import { CANCELLED } from 'utils/constants'
import styled from 'styled-components'

const EntryOrderStyled = styled.button`
  background-color: ${({ theme }) => theme.colors.grey_mid} !important;
  display: block;
  width: 100%;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 10px;

  h2 {
    ${({ theme }) => theme.fonts.h2};
    color: ${({ theme }) => theme.colors.dark_purple};
  }

  p {
    ${({ theme }) => theme.fonts.text_s};
    color: ${({ theme }) => theme.colors.dark_purple_mid};
  }

  .cancelled {
    color: red;
  }
`

export default function EntryOrder({ order, box, delivery, ...rest }) {
  if (box)
    return (
      <EntryOrderStyled {...rest}>
        <h2>{box.name}</h2>
        {box.status === CANCELLED && (
          <p className="cancelled">Editar entrega retornada</p>
        )}
      </EntryOrderStyled>
    )
  if (delivery)
    return (
      <EntryOrderStyled {...rest}>
        <h2>Entrega {new Date(delivery.createdAt).toLocaleDateString()}</h2>
        <p className="cancelled">{delivery.supervisorComment}</p>
      </EntryOrderStyled>
    )
  return (
    <EntryOrderStyled {...rest}>
      <h2>{order.cluster}</h2>
      <p>{order.project.title}</p>
    </EntryOrderStyled>
  )
}
