import { BadgeSistel, IconosSistel } from 'npm-styles-sistel'
import { CANCELLED, VALIDATED } from 'utils/constants'
import { NotificacionPEXStyle } from './AlertsStyle'

export default function NotificationCard({ notification, read, actions }) {
  const { markAsRead, deleteNotification } = actions

  const type =
    notification.ref && notification.comments === CANCELLED
      ? 'Entrega cancelada'
      : notification.ref && notification.comments === VALIDATED
      ? 'Entrega validada'
      : !notification.ref && notification.qty > 0
      ? 'Puntos extra'
      : 'Penalización'

  const badgeTipo =
    type === 'Entrega validada' ? (
      <BadgeSistel success>{type}</BadgeSistel>
    ) : type === 'Entrega cancelada' ? (
      <BadgeSistel danger>{type}</BadgeSistel>
    ) : type === 'Penalización' ? (
      <BadgeSistel danger>{type}</BadgeSistel>
    ) : type === 'Puntos extra' ? (
      <BadgeSistel success>{type}</BadgeSistel>
    ) : null

  return (
    <NotificacionPEXStyle key={notification?._id} estado={read} tipo={type}>
      <div className="contenido-icono">
        {badgeTipo}
        <button
          className="eliminar"
          onClick={() => deleteNotification(notification._id)}
        >
          <IconosSistel icon="eliminar" color="#D5D1E1" width="20" />
        </button>
      </div>
      <div className="ml-1 mt-2 d-flex">
        {read ? null : <span className="dot">.</span>}
        <div>
          <p>{notification.comments}</p>
          <div className="d-flex">
            <p className="dia">
              {new Date(notification.createdAt).toLocaleDateString()}
            </p>
            <p className="hora">
              {new Date(notification.createdAt).toLocaleTimeString()}
            </p>
          </div>
        </div>
      </div>
      <div className="leido">
        {read ? (
          <div className="btn-leido btn-leido-si">Leído</div>
        ) : (
          <div
            className="btn-leido btn-leido-no"
            onClick={() => markAsRead(notification._id)}
          >
            Marcar como leído
          </div>
        )}
      </div>
    </NotificacionPEXStyle>
  )
}
