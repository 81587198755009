import { Route, Switch } from 'react-router-dom'
import * as P from 'pages'

export const routes = (
  <Switch>
    <Route exact path="/" component={P.Dashboard} />
    <Route exact path="/trabajo" component={P.Trabajo} />
    <Route exact path="/trabajo/:orderId" component={P.TrabajoDetalles} />
    <Route exact path="/alertas" component={P.Alertas} />
    <Route exact path="/registrar-entrega" component={P.RegistrarEntrega} />
    <Route
      path="/registrar-entrega/:orderId"
      component={P.RegistrarEntregaDetalles}
    />
    <Route exact path="/puntos" component={P.Puntos} />
    <Route exact path="/perfil" component={P.Usuario} />
    <Route exact path="/perfil/cambiar-password" component={P.CambioPassword} />
  </Switch>
)
