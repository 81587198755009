import SinResultados from 'components/Common/SinResultados'
import NotificationCard from './NotificationCard'

export default function Notifications({ notifications, actions }) {
  if (!notifications.length) return <WithoutNotifications />

  const displayNotifications = notifications
    .map(({ notification, read }) => (
      <NotificationCard
        {...{ notification, read, actions }}
        key={notification._id}
      />
    ))
    .reverse()

  return displayNotifications
}

function WithoutNotifications() {
  return <SinResultados>No tienes notificaciones</SinResultados>
}
