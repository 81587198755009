import { toast } from 'react-toastify'
import { BASE_PATH, TOKEN } from 'utils/constants'

/**
 *
 * @param {string} notificationId _id of the notification to mark as read
 * @returns {true} Return true if the notification was marked as read
 */

export async function markAsReadAPI(notificationId) {
  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(TOKEN)
      },
      method: 'PUT'
    }

    const url = `${BASE_PATH}/notification/${notificationId}`
    const response = await fetch(url, options)
    if (!response.ok)
      throw new Error('La notificación no se ha podido marcar como leída.')

    toast.success('Notificación marcada como leída.')
    return true
  } catch (error) {
    console.error(error)
    toast.error(error)
    return null
  }
}

/**
 *
 * @param {string} notificationId _id of the notification to delete
 * @returns {true} Returns true if the notification was deleted
 */

export async function deleteNotificationAPI(notificationId) {
  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(TOKEN)
      },
      method: 'DELETE'
    }

    const url = `${BASE_PATH}/notification/${notificationId}`
    const response = await fetch(url, options)
    if (!response.ok)
      throw new Error('La notificación no se ha podido eliminar.')

    toast.success('Notificación eliminada correctamente.')
    return true
  } catch (error) {
    console.error(error)
    toast.error(error)
    return null
  }
}
