import { GroupData } from 'components/StyledComponents/mainStyles'
import { DataGroupSistel } from 'npm-styles-sistel'

export default function ProjectData({ project, zone, cluster }) {
  if (!project) return null

  return (
    <div className="flex-data">
      <DataGroupSistel
        title="Zona"
        data={`${project && project.city} - ${zone}`}
      />
      <DataGroupSistel
        title="Cluster"
        data={cluster}
      />
     
    </div>
  )
}
