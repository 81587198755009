export const BASE_PATH =
  String(process.env.NODE_ENV) !== 'development'
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:5000/api'

export const TOKEN = '@sistelEmpleadosPEXtoken'

// EMPLOYEE DELIVERY STATUS
export const PENDING = 'PENDING' // DEFAULT IN DB
export const CANCELLED = 'CANCELLED'
export const VALIDATED = 'VALIDATED'

export const DELIVERY_STATUS = { PENDING, CANCELLED, VALIDATED }

// ORDER STATUS
export const ASSIGNED = 'ASSIGNED'
export const CERTIFIED = 'CERTIFIED'
export const FINISHED = 'FINISHED'
export const GAP = 'GAP'
export const INCIDENCE = 'INCIDENCE'
export const IN_PROGRESS = 'IN_PROGRESS'
export const PENDING_VALIDATE = 'PENDING_VALIDATE'
export const UNASSIGNED = 'UNASSIGNED'
export const ORDER_STATUS = [
  ASSIGNED,
  CERTIFIED,
  FINISHED,
  GAP,
  INCIDENCE,
  IN_PROGRESS,
  PENDING_VALIDATE,
  UNASSIGNED
]

export const FUSIONADOR = 'FUSIONADOR'
export const CELADOR = 'CELADOR'
export const ORDER_TYPES = [FUSIONADOR, CELADOR]
