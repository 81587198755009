import { createContext, useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { fetchData } from 'service/api'
import { TOKEN } from '../utils/constants'

const AuthContext = createContext({})

export function AuthContextProvider({ children }) {
  const history = useHistory()
  const [auth, setAuth] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [realoadUser, setReloadUser] = useState(false)

  useEffect(() => {
    if (auth && !auth.user) {
      fetchData(auth.token, `/user/pex/${auth.userId}`).then((user) => {
        if (user) {
          setAuth((prev) => ({
            ...prev,
            user
          }))
          setLoading(false)
        }
      })
    }
  }, [auth?.userId])

  useEffect(() => {
    const token = localStorage.getItem(TOKEN)

    if (token) {
      const decoded = jwtDecode(token)

      const isExpiredToken = decoded.exp < new Date().getTime() / 1000
      if (isExpiredToken) {
        return logout()
      }

      setAuth({
        token,
        userId: jwtDecode(token)._id
      })
    } else {
      setAuth(null)
      history.push('/login')
    }
    setReloadUser(false)
  }, [realoadUser])

  const login = ({ token }) => {
    localStorage.setItem(TOKEN, token)
    history.push('/')
    setAuth({
      token,
      userId: jwtDecode(token)._id
    })
  }

  const logout = () => {
    localStorage.removeItem(TOKEN)
    setAuth(null)
    setLoading(true)
    history.push('/')
  }

  const updateUser = (identifier, newData) =>
    setAuth({ ...auth, user: { ...auth.user, [identifier]: newData } })

  const authData = useMemo(
    () => ({
      auth,
      login,
      logout,
      loading,
      updateUser,
      setReloadUser
    }),
    [auth, loading]
  )

  return (
    <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
  )
}

export default AuthContext
