import { DataGroupSistel } from 'npm-styles-sistel'

export default function BrigadeInfo({ users, brigadeComment }) {
  if (!users || !users.length) return null

  return (
    <>
      <DataGroupSistel
        title="Brigada"
        data={users.map((user) => `${user.name} ${user.lastname}`)}
      />
      {brigadeComment && (
        <DataGroupSistel
          title="Comentarios sobre la brigada"
          data={brigadeComment}
        />
      )}
    </>
  )
}
