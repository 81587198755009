import styled from 'styled-components'

export const TableStyled = styled.div`
  padding-bottom: 24px;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }

  thead {
    td {
      font-size: 18px;
      color: ${({ theme }) => theme.colors.text_dark};
      font-weight: 600;
      padding: 16px 0;
      padding-left: 12px;
      border-top: none;

      &:not(:first-child) {
        padding-left: 24px;
      }
    }
  }

  input {
    border: 1px solid ${({ theme }) => theme.colors.primary_light};
    padding: 8px 12px;
    border-radius: 6px;
    margin-right: 8px;

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.blue};
      outline: none;
    }
  }

  .quitar-baremo {
    background-color: ${({ theme }) => theme.colors.orange_dark};
    color: white;
    border-radius: 6px;
    width: 33px;
    height: 33px;
  }

  .penalizacion {
    background-color: ${({ theme }) => theme.colors.orange_light};
  }

  .bonificacion {
    background-color: ${({ theme }) => theme.colors.green_light};
  }

  th {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.text_dark};
    font-weight: 600;
    padding: 16px 0;
    padding-left: 12px;

    &:not(:first-child) {
      padding-left: 24px;
    }
  }

  tr {
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f9fafb;
    }
  }

  td {
    border-top: 1px solid ${({ theme }) => theme.colors.primary_light};
    padding: 12px 0;
    color: ${({ theme }) => theme.colors.text_dark};
    font-weight: 400;
    font-size: 16px;
    padding-left: 12px;

    &:not(:first-child) {
      padding-left: 24px;
    }
  }

  .fa {
    font-size: 12px;
  }

  select {
    float: right;
    padding: 10px;
    border-radius: 6px;
    border-color: ${({ theme }) => theme.colors.primary_light};
  }

  .badge {
    color: white;
    padding: 2px 8px;
    margin: 0 2px;
    border-radius: 6px;
    font-size: 12px;
    float: right;
  }

  .default {
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.primary_light};
  }

  .warning {
    background-color: ${({ theme }) => theme.colors.warning};
  }

  .success {
    background-color: ${({ theme }) => theme.colors.success};
  }

  .danger {
    background-color: ${({ theme }) => theme.colors.danger};
  }

  .iconos {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }

  .avatar {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      object-fit: cover;
      margin-right: 12px;
    }
  }
`

export const PaginationStyled = styled.ul`
  display: flex;
  padding: 0 0 24px 0;

  li {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.text_dark};
    cursor: pointer;
  }

  .active {
    background-color: ${({ theme }) => theme.colors.primary_light};
  }
`

export const SearchForm = styled.form`
  padding-bottom: 42px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  input {
    background-image: url('/images/search.png');
    background-repeat: no-repeat;
    background-position: 14px 10px;
    padding: 14px 60px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.text_dark};
    font-family: ${({ theme }) => theme.fonts.text};
    border: 1.5px solid ${({ theme }) => theme.colors.primary_light};
    border-radius: 6px;
    min-width: 50%;

    &:focus {
      border: 1.5px solid ${({ theme }) => theme.colors.blue};
      outline: none;
    }
  }
`

export const ProgressBar = styled.progress`
  display: block; /* default: inline-block */
  width: 180px;
  height: 10px;
  background: ${({ theme }) => theme.colors.primary_light};
  border-radius: 14px;

  &::-moz-progress-bar {
    border-radius: 14px;
    background: ${({ theme }) => theme.colors.primary_light};
  }

  &::-webkit-progress-value {
    border-radius: 14px;
    background: ${({ theme }) => theme.colors.orange};
  }

  &::-webkit-progress-bar {
    background: transparent;
  }
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin-bottom: 24px;

  label {
    display: block;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.darkBlue};
    font-weight: 600;
    margin-bottom: 12px;
  }

  input,
  select {
    border: 1px solid ${({ theme }) => theme.colors.primary_light};
    padding: 14px 16px;
    border-radius: 6px;

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.blue};
      outline: none;
    }
  }

  select {
    background: url('http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png')
      no-repeat right;
    -webkit-appearance: none;
    background-position-x: 96%;
    cursor: pointer;
  }
`

export const FormXS = styled.form`
  width: 500px;
  .buttons-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;
  }
`

export const FormXL = styled.form`
  h2 {
    color: ${({ theme }) => theme.colors.blue};
    border-bottom: 1px solid ${({ theme }) => theme.colors.blue};
    padding: 16px 0;
    margin-bottom: 24px;
    font-size: 20px;
  }
  .grid-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 32px;
  }

  .grid-form-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
  }

  .buttons-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;
    gap: 12px;
  }

  .flex-checkbox {
    display: flex;
    gap: 32px;
    padding-top: 10px;
  }

  .css-yk16xz-control {
    border: 1px solid ${({ theme }) => theme.colors.primary_light};
    padding: 4px 8px;
    border-radius: 6px;

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.blue};
      outline: none;
    }
  }

  @media (max-width: 1600px) {
    .grid-form {
      grid-template-columns: 1fr 1fr;
    }
  }
`

export const InputSubmit = styled.input`
  border: none;
  display: inline-block;
  padding: 8px 20px;
  font-size: 16px;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 1px;
  background-color: ${({ theme }) => theme.colors.success};
  color: white;
  cursor: pointer;
  outline: none;
`
