import { BASE_PATH } from 'utils/constants'

/**
 *
 * @param {{dni: string, password: string}} values Object with form values
 * @param {Function} cb Callback auth function
 * @returns {boolean} Return true if the login is successful and null if there is a problem
 */

export const loginApi = async (values, cb) => {
  try {
    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(values)
    }

    const response = await fetch(`${BASE_PATH}/login`, options)
    if (!response.ok) throw await response.text()
    const data = await response.json()
    cb(data)
    return true
  } catch (error) {
    console.error(error)
    return null
  }
}
