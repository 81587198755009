import { Link } from 'react-router-dom'
import TableSistel from 'components/Common/Table'

export default function PointOrders({ points = [] }) {
  const displayData = points.map((p) => (
    <tr key={p.createdAt}>
      {p.order ? (
        <td className="font-weight-bold">
          <Link to={`/trabajo/${p.order._id}`} className="table-link">
            {p.order.ref}
          </Link>
        </td>
      ) : (
        <td>{p.qty > 0 ? 'Bonficación' : 'Penalización'}</td>
      )}
      <td className="text-right">{p.qty.toFixed(2)}</td>
    </tr>
  ))
  return (
    <TableSistel
      headers={
        <>
          <th>Descripción</th>
          <th className="text-right">Puntos</th>
        </>
      }
      data={displayData}
    />
  )
}
