import styled from 'styled-components'

export const EmployeeDeliveryStyle = styled.div`
  margin-bottom: 16px;
  box-shadow: ${({ theme }) => theme.shadows.main_shadow};
  padding: 24px;
  margin-bottom: 16px;
  border-radius: 10px;

  .resumen {
    width: 100%;
    background-color: white;

    div {
      display: flex;
      align-items: center;
    }

    h2 {
      margin: 0 8px 0 0;
      ${({ theme }) => theme.fonts.h4};

    }
  }
  .avatar {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkText};
    margin-right: 24px;
    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 100%;
      margin-right: 12px;
    }
    div {
      display: block;
      text-align: left;
      span {
        font-size: 11px;
        color: ${({ theme }) => theme.colors.text};
      }
    }
  }

  .flex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
  h3 {
    color: ${({ theme }) => theme.colors.blue};
    margin-bottom: 10px;
  }

  h4 {
    ${({ theme }) => theme.fonts.h4};
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: 12px;
  }
`
