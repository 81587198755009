import SinResultados from 'components/Common/SinResultados'
import PointHistory from 'components/Points/PointHistory'

export default function HistoryByMonths({ pointHistory }) {
  if (!pointHistory.length) return <WithoutHistory />

  return <PointHistory data={pointHistory} />
}

function WithoutHistory() {
  return <SinResultados>Todavía no tienes historial de puntos</SinResultados>
}
