import { IconosSistel } from 'npm-styles-sistel'
import { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import styled from 'styled-components'

const ButtonStyled = styled.button`
width: 42px;
height: 42px;
border-radius: 10px;
background-color: ${({theme}) => theme.colors.grey_mid};
`

export default function Attached({ images }) {
  if (!images || !images.length) return null
  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(0)

  return (
    <>
      <ButtonStyled type="button" onClick={() => setOpen(true)}>
        <IconosSistel icon="mapa" />
      </ButtonStyled>
      {open && (
        <Lightbox
          mainSrc={images[index]}
          nextSrc={images[(index + 1) % images.length]}
          prevSrc={images[(index + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setIndex((index + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setIndex((index + 1) % images.length)}
        />
      )}
    </>
  )
}
