import { LightboxImageSistel } from 'npm-styles-sistel'

export default function DeliveryBoxAttachments({ photos }) {
  const titleHashMap = {
    panoramic: 'panoramicas',
    closedBox: 'de caja cerrada',
    openBox: 'de caja abierta',
    cableLabeling: 'cableado',
    powerMeasure: 'medición de potencia'
  }

  const displayAttachments = Object.keys(photos).map((selector) =>
    photos[selector]?.length ? (
      <div key={selector}>
        <h4>Fotos {titleHashMap[selector]}</h4>
        <LightboxImageSistel images={photos[selector]} />
        <hr />
      </div>
    ) : null
  )

  return <>{displayAttachments}</>
}
