import { AvatarImageSistel } from 'npm-styles-sistel'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import useAuth from 'hooks/useAuth'

const StyledTopNavbar = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  filter: drop-shadow(0px -5px 15px rgba(192, 185, 198, 0.3));
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 34;

  .name {
    display: flex;
    align-items: center;
  }
`

export default function TopNavbar() {
  const {
    auth: { user }
  } = useAuth()
  return (
    <StyledTopNavbar>
      <Link to="/" className="name">
        <img src="/images/logo_top.png" className="logo" />
        <h1>¡Hola, {user?.name}!</h1>
      </Link>
      <Link to="/perfil">
        <AvatarImageSistel image={user?.avatar} width="42px" />
      </Link>
    </StyledTopNavbar>
  )
}
