import OrderSummary from 'components/Orders/OrderSummary'
import useGlobalData from 'hooks/useGlobalData'
import { LoaderSistel } from 'npm-styles-sistel'
import { Tabs, Tab } from 'react-bootstrap'
import { FINISHED, IN_PROGRESS, PENDING_VALIDATE } from 'utils/constants'
import SinResultados from 'components/Common/SinResultados'

export default function Trabajo() {
  const { data } = useGlobalData(['orderList'])
  const orders = data.orderList

  if (!orders) return <LoaderSistel />

  const activeOrders = orders
    .filter((order) => !order.status || order.status === IN_PROGRESS)
    .map((order) => <OrderSummary key={order._id} order={order} />)

  const finishedOrders = orders
    .filter(
      (order) => order.status === FINISHED || order.status === PENDING_VALIDATE
    )
    .map((order) => <OrderSummary key={order._id} order={order} />)

  return (
    <>
      <Tabs defaultActiveKey="activas">
        <Tab eventKey="activas" title="Activas">
          {activeOrders.length ? (
            activeOrders
          ) : (
            <SinResultados>No tienes órdenes activas</SinResultados>
          )}
        </Tab>
        <Tab eventKey="finalizadas" title="Finalizadas">
          {finishedOrders.length ? (
            finishedOrders
          ) : (
            <SinResultados>No tienes órdenes finalizadas</SinResultados>
          )}
        </Tab>
      </Tabs>
    </>
  )
}
