import { UserNavbarStyled } from './navbarStyles'
import { NavLink, useLocation } from 'react-router-dom'
import { IconosSistel } from 'npm-styles-sistel'
import useAuth from 'hooks/useAuth'

export default function Navbar() {
  const {
    auth: { user }
  } = useAuth()

  const { pathname } = useLocation()
  const isActive = (link) => pathname.includes(link)

  const unreadNotifications = user.notifications.filter(
    (notification) => !notification.read
  )
  return (
    <UserNavbarStyled>
      <ul>
        <NavLink to="/" exact activeClassName="active-navitem">
          <li>
            <IconosSistel
              icon="icono"
              width="22"
              color={pathname === '/' ? '#5F35FF' : '#4B0787'}
            />
            <p>Inicio</p>
          </li>
        </NavLink>
        <NavLink to="/puntos" activeClassName="active-navitem">
          <li>
            <IconosSistel
              icon="puntos"
              width="23"
              color={isActive('/puntos') ? '#5F35FF' : '#4B0787'}
            />
            <p>Puntos</p>
          </li>
        </NavLink>

        <NavLink to="/registrar-entrega" activeClassName="active-navitem">
          <li className="registrar-entrega">
            <IconosSistel icon="mas" color="#5F35FF" width="50" />
          </li>
        </NavLink>

        <NavLink to="/trabajo" activeClassName="active-navitem">
          <li>
            <IconosSistel
              icon="ordenes"
              width="18"
              color={isActive('/trabajo') ? '#5F35FF' : '#4B0787'}
            />
            <p>Trabajo</p>
          </li>
        </NavLink>

        <NavLink to="/alertas" activeClassName="active-navitem">
          <li>
            <span className="counter">{unreadNotifications.length}</span>
            <IconosSistel
              icon="notificaciones"
              width="19"
              color={isActive('/alertas') ? '#5F35FF' : '#4B0787'}
            />
            <p>Alertas</p>
          </li>
        </NavLink>
      </ul>
    </UserNavbarStyled>
  )
}
