import { markAsReadAPI, deleteNotificationAPI } from 'api/notifications'
import useAuth from 'hooks/useAuth'
import Notifications from 'components/Alerts/Notifications'
import { PendingNotificationsStyled } from 'components/Alerts/AlertsStyle'
import { IconosSistel } from 'npm-styles-sistel'

export default function Alertas() {
  const {
    auth: { user },
    updateUser
  } = useAuth()
  const { notifications } = user

  const actions = {
    async markAsRead(id) {
      try {
        await markAsReadAPI(id)

        const updatedNotifications = notifications.map((n) => {
          if (n.notification._id === id)
            return { notification: n.notification, read: true }
          return n
        })

        updateUser('notifications', updatedNotifications)
      } catch (error) {
        console.error(error)
      }
    },

    async deleteNotification(id) {
      try {
        await deleteNotificationAPI(id)

        const updatedNotifications = notifications.filter(
          ({ notification }) => notification._id !== id
        )
        updateUser('notifications', updatedNotifications)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const pendingNotifications = notifications.filter(
    (n) => n.read === false
  ).length

  return (
    <>
      {pendingNotifications && (
        <PendingNotificationsStyled>
          <IconosSistel icon="notificaciones" color="white" width="20" />
          <p>
            Tienes {pendingNotifications}{' '}
            {pendingNotifications > 1 ? 'mensajes' : 'mensaje'} sin leer
          </p>
        </PendingNotificationsStyled>
      )}
      <Notifications {...{ notifications, actions }} />
    </>
  )
}
