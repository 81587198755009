import { useParams } from 'react-router'

import useGlobalData from 'hooks/useGlobalData'

import {
  WorkHeader,
  ProjectData,
  NewDeliveryButton,
  EmployeeDeliveries,
  BaremoList,
  BoxList
} from 'components/Order'

import { OrdenDetallesPEXstyled } from 'components/StyledComponents/orderStyle'
import { DataGroupSistel, LoaderSistel } from 'npm-styles-sistel'

export default function TrabajoDetalles() {
  const { orderId } = useParams()
  const { data } = useGlobalData(['order'], [orderId])
  const order = data.order[orderId]

  if (!order) return <LoaderSistel />

  order.employeeDeliveries = order.employeeDeliveries.map((ed) => {
    ed.baremoEntries = ed.baremoEntries.map((be, i) => {
      be.baremo = order.baremoList[i].baremo
      return be
    })
    return ed
  })
  const orderWithBoxes = order.type === 'FUSIONADOR'

  return (
    <OrdenDetallesPEXstyled>
      <WorkHeader order={order} />

      <ProjectData
        project={order.project}
        zone={order.zone}
        cluster={order.cluster}
      />
      <DataGroupSistel
        title="Comentarios del supervisor"
        data={order.supervisorComment || 'Sin datos'}
      />

      <BaremoList baremoList={order.baremoList} />
      <BoxList boxList={order.boxList} />
      <EmployeeDeliveries order={order} withBox={orderWithBoxes} />
      {!orderWithBoxes && <NewDeliveryButton order={order} />}
    </OrdenDetallesPEXstyled>
  )
}
