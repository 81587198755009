import styled from 'styled-components'

export const TrabajoPEXstyle = styled.div`
  .ordenes {
    margin-top: 24px;

    .orden {
      background-color: ${({ theme }) => theme.colors.primary_light};
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90px;
      border-radius: 10px;
      margin-bottom: 20px;

      p {
        color: ${({ theme }) => theme.colors.primary};
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
`

export const TrabajoPEXdetallesStyle = styled.div`
  hr {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  h2 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
    font-size: 18px;
  }

  h3 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text};
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 24px;
    margin-top: 42px;
  }

  .opacity {
    opacity: 0.2;
  }

  .flex-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0;
    & > button:not(:first-child) {
      margin-left: 20px;
    }

    & > div:not(:first-child) {
      margin-left: 20px;
    }
    & > label:not(:first-child) {
      margin-left: 20px;
    }

    button {
      background-color: transparent;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  h4 {
    color: ${({ theme }) => theme.colors.text};
    ${({ theme }) => theme.fonts.h4};
    text-align: center;
    margin: 24px 0;
  }

  h5 {
    color: ${({ theme }) => theme.colors.text_light};
    margin: 8px;
    font-weight: 500;
    font-size: 14px;
  }

  input {
    text-align: center;
  }

  .brigada {
    text-align: center;
    margin-top: 42px;
  }

  .users {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;

    .brigade-user {
      font-size: 10px;
      font-weight: 600;
      padding: 8px 10px;
      border-radius: 4px;
      margin-bottom: 8px;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.dark_purple};
      background-color: ${({ theme }) => theme.colors.grey_light};
    }
  }

  .fotos {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    height: 90px;
    font-size: 20px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.darkText};
    background-color: ${({ theme }) => theme.colors.grey};
    font-weight: 500;
    border-radius: 10px;
  }

  .lista-imagenes {
    display: flex;
    margin-top: 24px;

    button {
      background-color: transparent;
      position: relative;

      &:before {
        content: url('/images/close.svg');
        width: 20px;
        height: 20px;
        position: absolute;
        top: 35px;
        left: 40%;
        z-index: 2;
      }

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        margin: 8px;
        border-radius: 8px;
        filter: brightness(70%);
      }
    }
  }
`
