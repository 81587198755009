import styled from 'styled-components'

const StyledErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  margin: 1em 0;
  p {
    text-align: center;
    color: #d74c4c;
  }
`

export default function ErrorMessage({ message = '' }) {
  return (
    <StyledErrorMessage>
      <p>{message}</p>
    </StyledErrorMessage>
  )
}
