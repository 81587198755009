import { SinResultadosStyled } from 'components/StyledComponents/mainStyles'
import { IconosSistel } from 'npm-styles-sistel'

export default function SinResultados({ children }) {
  return (
    <SinResultadosStyled>
      <IconosSistel icon="buscar" width="50" color="#E1CFEA" />
      <p>{children}</p>
    </SinResultadosStyled>
  )
}
