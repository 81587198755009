import { useState } from 'react'
import { useHistory, useParams } from 'react-router'

import useGlobalData from 'hooks/useGlobalData'
import { CANCELLED, CELADOR } from 'utils/constants'

import NewEntryForm from 'components/Common/Forms/NewEntryForm'
import NewEntryWithBox from 'components/Common/Forms/NewEntryWithBox'
import BoxSelector from 'components/NewEntry/BoxSelector'
import CeladorSelector from 'components/NewEntry/CeladorSelector'
import { WorkHeader } from 'components/Order'

import { TrabajoPEXdetallesStyle } from 'components/NewEntry/NewEntryStyle'
import { LoaderSistel } from 'npm-styles-sistel'

export default function RegistrarEntregaDetalles() {
  const { orderId } = useParams()
  const history = useHistory()
  const delivery = history.location.state?.delivery

  const [step, setStep] = useState(delivery ? 1 : 0)
  const [selectedBox, setSelectedBox] = useState(undefined)
  const [selectedDelivery, setSelectedDelivery] = useState(delivery)

  const { data } = useGlobalData(['order'], [orderId])
  const order = data.order[orderId]

  if (!order) return <LoaderSistel />

  const nextStep = (type, selection) => {
    if (type)
      type === CELADOR
        ? setSelectedDelivery(selection)
        : setSelectedBox(selection)
    setStep(1)
  }

  if (order.type === CELADOR) {
    const cancelledDeliveries = order.employeeDeliveries.filter(
      (delivery) => delivery.status === CANCELLED
    )

    if (!cancelledDeliveries.length) {
      return <CeladorDelivery order={order} delivery={selectedDelivery} />
    }

    switch (step) {
      case 0:
        return (
          <CeladorSelector deliveries={cancelledDeliveries} {...{ nextStep }} />
        )
      case 1:
        return <CeladorDelivery order={order} delivery={selectedDelivery} />
    }
  }

  switch (step) {
    case 0:
      return (
        <BoxSelector
          boxList={order.boxList}
          deliveries={order.employeeDeliveries}
          {...{ nextStep }}
        />
      )
    case 1: {
      const data = selectedBox || {
        ...order.boxList.find((b) => b._id === selectedDelivery.boxId)
      }

      return (
        <TrabajoPEXdetallesStyle>
          <WorkHeader order={order} box={data.name} />
          <NewEntryWithBox
            order={order}
            box={selectedBox || { ...data, delivery: selectedDelivery }}
          />
        </TrabajoPEXdetallesStyle>
      )
    }
  }
}

function CeladorDelivery({ order, delivery }) {
  return (
    <TrabajoPEXdetallesStyle>
      <WorkHeader {...{ order }} />
      <NewEntryForm {...{ order, delivery }} />
    </TrabajoPEXdetallesStyle>
  )
}
