import { ButtonSistel, LightboxImageSistel } from 'npm-styles-sistel'
import ImageUploading from 'react-images-uploading'

export default function AttachmentsFormElement({
  photos,
  onChange,
  maxNumber = 10,
  previousImg = false
}) {
  return (
    <>
      <h4 className="mb-3">Fotos</h4>
      {previousImg && <LightboxImageSistel images={previousImg} />}
      <ImageUploading
        multiple
        value={photos}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
          <div className="upload__image-wrapper">
            <ButtonSistel
              type="button"
              block
              edit
              onClick={onImageUpload}
              {...dragProps}
            >
              + Subir fotos
            </ButtonSistel>
            <div className="lista-imagenes">
              {imageList.map((image, index) => (
                <button key={index} onClick={() => onImageRemove(index)}>
                  <img src={image.data_url} />
                </button>
              ))}
            </div>
          </div>
        )}
      </ImageUploading>
      <hr />
    </>
  )
}
