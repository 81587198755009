import { ResumenPuntosStyles } from '../StyledComponents/dashboardStyles'
import PointChart from './PointChart'
import useAuth from 'hooks/useAuth'

export default function PointSummary() {
  const {
    auth: { user }
  } = useAuth()
  const { points, department } = user

  const pointSummary = Math.round(points.reduce((a, b) => a + b.qty, 0))
  const employeeGoal = user.monthlyGoal || department.monthlyGoal

  return (
    <ResumenPuntosStyles>
      <div>
        <h3>Puntos</h3>
        <p>{pointSummary}</p>
        <h3 className="mt-3">Objetivo</h3>
        <p>{employeeGoal}</p>
      </div>

      <PointChart
        puntosConseguidos={pointSummary}
        objetivoMensual={employeeGoal}
      />
    </ResumenPuntosStyles>
  )
}
