import styled from 'styled-components'
import { Form } from 'formik'

export const SearchForm = styled.form`
  padding-bottom: 42px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  input {
    background-image: url('/images/search.png');
    background-repeat: no-repeat;
    background-position: 14px 10px;
    padding: 14px 14px 14px 60px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.darkText};
    font-family: ${({ theme }) => theme.fonts.text};
    border: 1.5px solid ${({ theme }) => theme.colors.grey};
    border-radius: 6px;
    min-width: 50%;

    &:focus {
      border: 1.5px solid ${({ theme }) => theme.colors.blue};
      outline: none;
    }
  }
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin-bottom: 24px;

  label {
    display: block;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.darkBlue};
    font-weight: 600;
    margin-bottom: 12px;
  }

  input,
  select,
  textarea {
    border: 1px solid
      ${(props) =>
        props.fieldError ? props.theme.colors.orange : props.theme.colors.grey};
    padding: 14px 16px;
    border-radius: 6px;

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.blue};
      outline: none;
    }
  }

  .input-error {
    border: 1px solid ${({ theme }) => theme.colors.orange};
  }

  .formik-error {
    color: ${({ theme }) => theme.colors.orange};
    font-size: 12px;
    padding: 12px 0 0 2px;
    font-weight: 500;

    i {
      padding-right: 8px;
    }
  }
`

export const FormikFormXS = styled(Form)`
  width: 500px;
  .buttons-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;
  }
`

export const FormikFormXL = styled(Form)`
  h2 {
    color: ${({ theme }) => theme.colors.blue};
    border-bottom: 1px solid ${({ theme }) => theme.colors.blue};
    padding: 16px 0;
    margin-bottom: 24px;
    font-size: 20px;
  }
  .grid-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 32px;
  }

  .grid-form-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
  }

  .buttons-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;
    gap: 12px;
  }

  .btn-baremos {
    margin-right: 1em;
  }

  .flex-checkbox {
    display: flex;
    gap: 32px;
    padding-top: 10px;
  }

  .css-yk16xz-control {
    border: 1px solid ${({ theme }) => theme.colors.grey};
    padding: 4px 8px;
    border-radius: 6px;

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.blue};
      outline: none;
    }
  }

  .lista-imagenes {
    margin-top: 24px;
    display: flex;
    align-items: center;

    button {
      width: 90px;
      height: 90px;
      padding: 0;
      margin-right: 12px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        filter: brightness(50%);
      }

      &::after {
        content: url('/images/white-close.png');
        position: relative;
        top: -56px;
        color: white;
      }
    }
  }

  @media (max-width: 1600px) {
    .grid-form {
      grid-template-columns: 1fr 1fr;
    }
  }
`

export const SubmitForm = styled.button`
  border: none;
  display: inline-block;
  padding: 8px 20px;
  font-size: 16px;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 1px;
  background-color: ${({ theme }) => theme.colors.success};
  color: white;
  cursor: pointer;
  outline: none;
`

export const SubmitFormFull = styled.button`
  margin-top: 20px;
  width: 100%;
  border: none;
  display: inline-block;
  padding: 20px;
  font-size: 16px;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 1px;
  background-color: ${({ theme }) => theme.colors.success};
  color: white;
  cursor: pointer;
  outline: none;
`

export const DeleteButton = styled.button`
  border: none;
  display: inline-block;
  margin-top: 33px;
  padding: 13px 16px;
  font-size: 16px;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 1px;
  color: white;
  cursor: pointer;
  outline: none;
  background-color: ${({ theme }) => theme.colors.danger};
`

export const ButtonBaremo = styled.button`
  background: transparent;
  border: none;
  outline: none;
  display: block;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.darkText};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
`

export const FormXS = styled.form`
  width: 500px;
  .buttons-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`

export const InputSubmit = styled.input`
  border: none;
  display: inline-block;
  padding: 8px 20px;
  font-size: 16px;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 1px;
  background-color: ${({ theme }) => theme.colors.success};
  color: white;
  cursor: pointer;
  outline: none;
`

export const FormGroupTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey_mid};

  label {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.text_light};
  }

  input {
    border: 1px solid ${({ theme }) => theme.colors.grey_mid};
    padding: 14px 16px;
    border-radius: 8px;
    width: 100px;
    margin-left: 12px;

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.blue};
      outline: none;
    }
  }
`

export const HeaderEntry = styled.div`
  background-color: ${({ theme }) => theme.colors.grey_light};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
`
