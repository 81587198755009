import styled from 'styled-components'

export const MainDashboardStyles = styled.div`
  h1 {
    font-size: 32px;
    padding-bottom: 42px;
  }

  .columns-dashboard {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
  }

  h2 {
    color: ${(props) => props.theme.colors.darkBlue};
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.5px;
  }

  .data-column {
    background-color: white;
    padding: 22px;
    border-radius: 6px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);

    .data-column-title {
      display: flex;
      align-items: center;
      background-color: white;
      padding: 14px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      margin-top: -48px;
      margin-bottom: 22px;
      width: 250px;

      img {
        padding-right: 10px;
      }
    }

    .states {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 8px;
    }

    .objetivo-semanal {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`

export const ResumenMensualStyles = styled.div`
  background-color: ${(props) => props.theme.blue_light};
  padding: 24px;
  padding-bottom: 48px;
  border-radius: 6px;
  margin-bottom: 60px;

  .data-group {
    display: flex;

    div {
      width: 25%;

      h3 {
        font-size: 60px;
        font-weight: 700;
        color: ${(props) => props.theme.colors.blue};
      }

      p {
        font-size: 18px;
        font-weight: 400;
        color: ${(props) => props.theme.colors.blue};
        text-transform: uppercase;
        padding-left: 2px;
      }
    }

    div:not(:first-child) {
      border-left: 2px solid rgba(66, 122, 178, 0.14);
      padding-left: 16px;
    }
  }
`

export const ResumenPuntosStyles = styled.div`
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.dark_purple};
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;


  h3 {
    color: ${({ theme }) => theme.colors.dark_purple_light};
    font-weight: 400;
    font-size: 14px;
  }

  p {
    ${({theme}) => theme.fonts.h2};
    color:${({ theme }) => theme.colors.white};
    margin-bottom: 16px;
  }

  p:last-child {
    margin-bottom: 0px;
  }
`

export const HeaderDashboard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 44px;
    height: 44px;
    overflow: hidden;
    border-radius: 10px;
    object-fit: cover;
  }
`


export const TopEmpleadoStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  margin-top: 42px;

  .empleado {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 16px;
      margin-bottom: 12px;
    }

    h6 {
      color: ${({ theme }) => theme.colors.darkBlue};
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .puntos {
      color: ${({ theme }) => theme.colors.blue};
      font-size: 36px;
      font-weight: 300;
    }
  }
`

export const ObjetivoConseguidoStyle = styled.div`
  background-color: ${({ theme }) => theme.colors.green_light};
  margin-top: 34px;
  padding: 26px;
  border-radius: 20px;

  .party {
    background-color: #77dfcc;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: -36px;
  }

  h4 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.success};
    font-size: 20px;
    margin: 16px 0;
  }

  p {
    color: ${({ theme }) => theme.colors.success};
    font-size: 18px;
  }

  .dinero {
    text-align: right;
    font-size: 32px;
    margin-top: 24px;
    font-weight: 600;
  }
`
