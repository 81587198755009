import { useHistory } from 'react-router-dom'
import { changePasswordApi } from 'api/common'
import { TrabajoPEXdetallesStyle } from 'components/NewEntry/NewEntryStyle'
import { FormikProvider, useFormik } from 'formik'
import { ButtonSistel, InputSistel } from 'npm-styles-sistel'

const initialValues = { newPw: '', newPw2: '', currentPw: '' }

export default function ChangePassword() {
  const history = useHistory()
  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      const { newPw, newPw2 } = values
      if (newPw !== newPw2) {
        return setErrors({ password: 'Las nuevas contraseñas no coinciden' })
      }
      setSubmitting(true)
      const response = await changePasswordApi(values)

      if (response) {
        resetForm()
        history.push('/')
      }
      setSubmitting(false)
    }
  })

  const { isSubmitting, handleSubmit, handleChange, values } = formik

  const { newPw, newPw2, currentPw } = values

  return (
    <TrabajoPEXdetallesStyle>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <h3>Cambiar contraseña</h3>
          <InputSistel
            value={currentPw}
            name="currentPw"
            placeholder="Contraseña actual"
            onChange={handleChange}
          />
          <InputSistel
            value={newPw}
            name="newPw"
            placeholder="Nueva contraseña"
            type="password"
            onChange={handleChange}
          />
          <InputSistel
            value={newPw2}
            name="newPw2"
            placeholder="Repite tu nueva contraseña"
            type="password"
            onChange={handleChange}
          />
          <div className="flex-buttons">
            <ButtonSistel type="submit" block>
              {isSubmitting ? '...' : 'Cambiar contraseña'}
            </ButtonSistel>
          </div>
        </form>
      </FormikProvider>
    </TrabajoPEXdetallesStyle>
  )
}
