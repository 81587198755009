import { useHistory } from 'react-router'

import { CANCELLED, FUSIONADOR } from 'utils/constants'

import EntryOrder from 'components/NewEntry/EntryOrder'

import { TrabajoPEXstyle } from 'components/NewEntry/NewEntryStyle'
import { ButtonSistel } from 'npm-styles-sistel'

export default function BoxSelector({ boxList, nextStep, deliveries }) {
  const history = useHistory()
  const returnDashboard = () => history.push('/')

  if (!boxList || !boxList.length)
    return (
      <div className="text-center">
        <h4>No hay cajas disponibles para esta orden</h4>
        <p>Contacta con tu superior</p>
      </div>
    )

  const filteredBoxes = boxList.reduce((prev, curr) => {
    const hasBox = deliveries.find((d) => d.boxId === curr._id)
    return !hasBox
      ? [...prev, curr]
      : hasBox.status === CANCELLED
      ? [...prev, { ...curr, status: CANCELLED, delivery: hasBox }]
      : prev
  }, [])

  const displayBoxes =
    filteredBoxes.length > 0 ? (
      filteredBoxes.map((box) => (
        <EntryOrder
          key={box._id}
          box={box}
          onClick={() => nextStep(FUSIONADOR, box)}
        />
      ))
    ) : (
      <div className="text-center mt-3">
        <p>Todas las cajas tienen entregas</p>
        <ButtonSistel className="mt-2" onClick={returnDashboard}>
          Volver a inicio
        </ButtonSistel>
      </div>
    )

  return (
    <TrabajoPEXstyle>
      <h1 className="mb-3">Cajas</h1>
      {displayBoxes}
    </TrabajoPEXstyle>
  )
}
