const { BASE_PATH } = require('utils/constants')

export async function fetchData(token, url) {
  try {
    const path = `${BASE_PATH}${url}`
    const response = await fetch(path, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
    if (!response.ok) throw await response.text()
    const data = await response.json()

    return data
  } catch (error) {
    console.error(error)
    return null
  }
}
