import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-image-lightbox/style.css'

import { routes } from './routes'
import Login from 'components/Auth/Login'
import Navbar from 'components/Navbar/Navbar'
import TopNavbar from 'components/Navbar/TopNavbar'
import { MainContainer } from 'components/StyledComponents/layout'
import DataContextProvider from 'context/DataContext'
import useAuth from 'hooks/useAuth'

import { LoaderSistel } from 'npm-styles-sistel'

export default function App() {
  const { auth, loading } = useAuth()

  if (auth === undefined) return null
  if (auth === null) return <Login />

  if (loading) return <LoaderSistel />

  return (
    <>
      <DataContextProvider>
        <TopNavbar />
        <MainContainer>{routes}</MainContainer>
        <Navbar />
      </DataContextProvider>

      <ToastContainer autoClose="3000" />
    </>
  )
}
