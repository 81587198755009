import { InputSistel } from 'npm-styles-sistel'

export default function EmployeeComment({ handleChange, value }) {
  return (
    <>
      <h4>Comentarios</h4>
      <InputSistel
        name="employeeComment"
        type="textarea"
        placeholder="¿Habéis tenido algún problema o queréis comentar algo?"
        onChange={handleChange}
        {...{ value }}
      />
      <hr />
    </>
  )
}
